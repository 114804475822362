<template>
  <div>
    <div class="d-trigger">
      <button :class="'button is-small ' + id">
        <span>{{ title }}</span>
        <span class="icon is-small">
          <i class="fas fa-angle-down" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div :id="id" class="d-content d-none">
      <ul>
        <slot />
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  props: {
    id: {
      type: String,
      default() {
        return `dropDow-${this._uid}`;
      },
    },
    title: {
      type: String,
      default: () => "Selectionner",
    },
  },
  mounted() {
    const $action = document.querySelector(`.${this.id}`);
    $action.addEventListener("click", this.getToggleDropDown);
  },
  methods: {
    getToggleDropDown(event) {
      event.stopPropagation();
      const $dropDown = document.querySelector(`#${this.id}`);
      $dropDown.classList.toggle("d-none");
    },
    closeExit() {
      const $dropDown = document.querySelector(`#${this.id}`);
      $dropDown.classList.toggle("d-none");
    },
  },
};
</script>

<style lang="scss" scoped>
.d-trigger {
  position: relative;
}
.d-none {
  display: none !important;
}
.d-content {
  position: absolute;
  border: 1px solid #fff;
  background: #fff;
  z-index: 2;
  box-shadow: 0 2rem 3rem rgba(132, 139, 200, 0.18);
  width: 170px;
  padding: 10px;
  ul {
    li {
      line-height: 30px;
      input {
        font-size: 12px;
      }
    }
  }
}
.v-select {
  font-size: 12px;
}
</style>