<template>
  <div>
    <dashboard-admin v-if="!is_mcp" />
    <dashboard-customer v-else />
  </div>
</template>

<script>
import dashboardAdmin from "./dashboard/admin.vue";
import dashboardCustomer from "./dashboard/customer.vue";
import { isMcp } from "@/utils/Auth";
export default {
  name: "Home",
  components: { dashboardAdmin, dashboardCustomer },
  data: () => ({
    is_mcp: false,
  }),
  created() {
    this.is_mcp = isMcp();
  },
};
</script>
