<template>
  <div>
    <div class="dash_box">
      <div class="d-first">
        <div class="box-content-app">
          <h3>Montant total remboursé</h3>
          <div class="detail_">
            <span class="st"
              ><strong
                >{{
                  operations.totalSoldeRefund ? operations.totalSoldeRefund : 0
                }}
                XOF</strong
              ></span
            >
            <svg
              data-v-8316392a=""
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                data-v-8316392a=""
                cx="23"
                cy="23"
                r="23"
                fill="#28C76F"
                fill-opacity="0.12"
              ></circle>
              <path
                data-v-8316392a=""
                d="M33 22.08V23C32.9988 25.1564 32.3005 27.2547 31.0093 28.9818C29.7182 30.709 27.9033 31.9725 25.8354 32.5839C23.7674 33.1953 21.5573 33.1219 19.5345 32.3746C17.5117 31.6273 15.7847 30.2461 14.611 28.4371C13.4373 26.628 12.8798 24.4881 13.0217 22.3363C13.1636 20.1846 13.9972 18.1363 15.3983 16.4971C16.7994 14.8578 18.6928 13.7154 20.7962 13.2401C22.8996 12.7649 25.1003 12.9823 27.07 13.86"
                stroke="#28C76F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                data-v-8316392a=""
                d="M33 15L23 25.01L20 22.01"
                stroke="#28C76F"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </div>
        <div class="box-content-app">
          <h3>Remboursement en attente</h3>
          <div class="detail_">
            <span class="st"
              ><strong
                >{{
                  operations.totalPeddingRefund
                    ? operations.totalPeddingRefund
                    : 0
                }}
                XOF</strong
              ></span
            >
            <svg
              data-v-8316392a=""
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                data-v-8316392a=""
                cx="23"
                cy="23"
                r="23"
                fill="#E5E5E5"
              ></circle>
              <path
                data-v-8316392a=""
                d="M23 33C28.5228 33 33 28.5228 33 23C33 17.4772 28.5228 13 23 13C17.4772 13 13 17.4772 13 23C13 28.5228 17.4772 33 23 33Z"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                data-v-8316392a=""
                d="M26 20L20 26"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="d-second">
        <div class="box-c">
          <div class="b-tools">
            <h3>Total transaction réussies, échoués par mois</h3>
            <div>
              <drop-down title="Recherche avancée" ref="dateSearch">
                <template>
                  <li>
                    <div>
                      <label>Année</label>
                      <v-select
                        :options="years"
                        placeholder="Selectionner"
                        v-model="model.year"
                        label="name"
                        :reduce="(item) => item.id"
                      ></v-select>
                    </div>
                  </li>
                  <li>
                    <div>
                      <label>Client</label>
                      <v-select
                        :options="customers"
                        placeholder="Selectionner"
                        v-model="model.spcode"
                        label="name"
                        :reduce="(item) => item.name"
                      ></v-select>
                    </div>
                  </li>
                  <li>
                    <div class="pt-10 b-flex">
                      <button
                        @click="changeYear"
                        class="button is-outlined is-small"
                      >
                        Valider
                      </button>
                      <button
                        @click="cancelSearch"
                        class="button is-outlined is-small"
                      >
                        Annuler
                      </button>
                    </div>
                  </li>
                </template>
              </drop-down>
            </div>
          </div>
          <p>
            <apexchart
              v-if="!loading_g"
              height="250"
              type="bar"
              :options="graph.chartOptions"
              :series="graph.series"
            ></apexchart>
          </p>
        </div>
      </div>
    </div>

    <div class="pt-10">
      <div class="chartContent">
        <div class="box-content">
          <div class="box-content-cam">
            <h3>Top 5 client transaction débit</h3>
            <p>
              <apexchart
                height="250"
                type="donut"
                v-if="!loading_d"
                :options="donusDebit.chartOptions"
                :series="donusDebit.series"
              ></apexchart>
            </p>
          </div>
        </div>
        <div class="box-content">
          <h3>Top 5 client transaction crédit</h3>
          <p>
            <apexchart
              height="250"
              type="donut"
              v-if="!loading_c"
              :options="donusCredit.chartOptions"
              :series="donusCredit.series"
            ></apexchart>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apexchart from "vue-apexcharts";
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import DropDown from "@/components/DropDown";
export default {
  name: "dashboardAdmin",
  components: { apexchart, DropDown },
  data: () => ({
    loading_d: false,
    loading_c: false,
    loading_g: false,
    model: {
      year: "",
      spcode: "",
    },
    years: [],
    donusDebit: {
      series: [],
      chartOptions: {
        chart: {
          type: "donut",
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
        },
        title: {
          text: "",
          align: "left",
        },
        responsive: [
          {
            breakpoint: 480,
          },
        ],
        labels: [],
      },
    },
    donusCredit: {
      series: [],
      chartOptions: {
        chart: {
          type: "donut",
        },
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
        },
        title: {
          text: "",
          align: "left",
        },
        responsive: [
          {
            breakpoint: 480,
          },
        ],
        labels: [],
      },
    },
    graph: {
      series: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 250,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: "Transactions",
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " Transactions";
            },
          },
        },
      },
    },
  }),
  computed: {
    ...mapGetters({
      operations: `${TYPES.modules.DASHBOARD}/${TYPES.getters.GET_ADMIN_OPERATION}`,
      customers: `${TYPES.modules.UTILS}/${TYPES.getters.GET_MCP}`,
    }),
  },
  mounted() {
    this.getOperations();
    this.getTransactionsChart();
    this.getDebit();
    this.getCredit();
    this.getCustomers();
    this.setuptYears();
  },
  methods: {
    getOperations() {
      this.$store
        .dispatch(
          `${TYPES.modules.DASHBOARD + "/" + TYPES.actions.GET_ADMIN_OPERATION}`
        )
        .then(() => {})
        .catch(() => {});
    },

    getCustomers() {
      this.$store.dispatch(
        `${TYPES.modules.UTILS + "/" + TYPES.actions.GET_MCP}`
      );
    },

    setuptYears() {
      const current_year = new Date().getFullYear();
      for (let index = 2015; index <= current_year; index++) {
        this.years.push({ id: index, name: index });
      }
    },

    changeYear() {
      this.getTransactionsChart();
      this.$refs.dateSearch.closeExit();
    },

    getDebit() {
      this.loading_d = true;
      this.$store
        .dispatch(
          `${TYPES.modules.DASHBOARD + "/" + TYPES.actions.GET_ADMIN_TOP_DEBIT}`
        )
        .then((res) => {
          this.donusDebit.series = res.data;
          this.donusDebit.chartOptions.labels = res.labels;
          this.loading_d = false;
        })
        .catch(() => {
          this.loading_d = false;
        });
    },

    cancelSearch() {
      this.model.year = "";
      this.model.spcode = "";
      this.$refs.dateSearch.closeExit();
    },

    getCredit() {
      this.loading_c = true;
      this.$store
        .dispatch(
          `${
            TYPES.modules.DASHBOARD + "/" + TYPES.actions.GET_ADMIN_TOP_CREDIT
          }`
        )
        .then((res) => {
          this.donusCredit.series = res.data;
          this.donusCredit.chartOptions.labels = res.labels;
          this.loading_c = false;
        })
        .catch(() => {
          this.loading_c = false;
        });
    },

    getTransactionsChart() {
      this.loading_g = true;
      this.$store
        .dispatch(
          `${
            TYPES.modules.DASHBOARD +
            "/" +
            TYPES.actions.GET_ADMIN_TRANSACTIONS_GRAPH
          }`,
          this.model
        )
        .then((res) => {
          this.graph.series = res.series;
          this.graph.chartOptions.xaxis.categories = res.labels;
          this.loading_g = false;
        })
        .catch(() => {
          this.loading_g = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dash_box {
  display: flex;
  gap: 10px;
  .d-first {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 15px;
  }
  .d-second {
    flex: 3;
  }
}
.box-content-app {
  background: #fff;
  //   box-shadow: 0 2rem 3rem rgba(132, 139, 200, 0.18);
  width: 100%;
  min-height: 140px;
  height: auto;
  padding: 10px;
}
.box-content-cam {
  background: #fff;
  //   box-shadow: 0 2rem 3rem rgba(132, 139, 200, 0.18);
  width: 100%;
  min-height: 250px;
  height: auto;
  padding: 10px;
}

.chartContent {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.box-content {
  flex: 1;
  background: #fff;
  //   box-shadow: 0 2rem 3rem rgba(132, 139, 200, 0.18);
  width: 100%;
  min-height: 250px;
  height: auto;
  padding: 10px;
}

.box-c {
  background: #fff;
  //   box-shadow: 0 2rem 3rem rgba(132, 139, 200, 0.18);
  width: 100%;
  height: 300px;
  padding: 10px;
}

.detail_ {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}
.st {
  font-size: 17px;
}
.b-tools {
  display: flex;
  justify-content: space-between;
}
.b-flex {
  display: flex;
  justify-content: space-between;
}
// @media only screen and (max-width: 1160px) {
//   .dash_box {
//     grid-template-columns: 1fr 1fr;
//     grid-gap: 10px;
//   }
// }

// @media only screen and (max-width: 650px) {
//   .dash_box {
//     grid-template-columns: 1fr;
//     grid-auto-flow: row;
//   }
// }
</style>